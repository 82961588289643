//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import Icon from "../../assets/images/highlights/1.svg";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      onComplete: null,
      loading: false,
      valid: false,
      file: null,
      hash: null,
      Icon
    };
  },
  mounted() {},
  methods: {
    onOpen({
      onComplete
    }) {
      this.onComplete = onComplete;
    },
    uploadFile(e) {
      this.file = e.target.files;
      this.valid = true;
    },
    dragFile(e) {
      this.file = e.dataTransfer.files;
      this.valid = true;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _result;
        let result = null;
        if (this.$store.state.connections.marketplace.title === "Wildberries") {
          result = await this.$store.dispatch("connections/importWbProducts", {
            file: this.file[0]
          });
        }
        if (this.$store.state.connections.marketplace.title === "OZON") {
          result = await this.$store.dispatch("connections/importOzProducts", {
            file: this.file[0]
          });
        }
        this.hash = (_result = result) === null || _result === void 0 ? void 0 : _result.data.hash;
        setInterval(() => {
          if (this.loading) {
            this.loadProgress(close);
          }
        }, 3000);
      } catch (e) {
        this.loading = false;
      }
    },
    async loadProgress(close) {
      var _response;
      let response = null;
      if (this.$store.state.connections.marketplace.title === "Wildberries") {
        response = await this.$store.dispatch("connections/importWbProductsState", {
          hash: this.hash
        });
      }
      if (this.$store.state.connections.marketplace.title === "OZON") {
        response = await this.$store.dispatch("connections/importOzProductsState", {
          hash: this.hash
        });
      }
      this.loading = ((_response = response) === null || _response === void 0 ? void 0 : _response.data.status) === "working";
      if (!this.loading) {
        this.file = null;
        this.hash = null;
        close();
        this.onComplete && this.onComplete(response);
        this.$emit("complete", response);
      }
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        text: "进口",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};